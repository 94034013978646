import Project from "../Project"
import ProjectFigure from "../ProjectFigure"

import ASFApp from "../../../Assets/asf-app.jpg"
import ASFBackoffice from "../../../Assets/asf-backoffice.jpg"
import ASFCarte from "../../../Assets/asf-carte.jpg"
import ASFFlyer from "../../../Assets/asf-flyer.jpg"
import ASFWebsite from "../../../Assets/asf-website.jpg"

const ProjectASF = () => {
	return (
		<Project
			type="Web Designer"
			date="2021 ~ 2022"
			name="Asian street food"
			challenges={[
				"Décomposer une nouvelle fonctionnalité en petites parties pour améliorer continuellement la valeur du produit",
				"Faire évoluer la plateforme en fonction de l'évolution du besoin.",
			]}
			skills={["figma", "html", "css", "js", "react", "php", "mysql"]}>
			<div className="project-missions">
				<section className="project-col-2">
					<div className="mission left top">
						<p className="title">Création & gestion de projets&nbsp;: site web & interface d’administration</p>
						<p className="detail">Prise de besoins, définition des objectifs et création d'un roadmap en fonction des priorités.</p>
						<p className="detail">Mise en place d'un design system selon un univers graphique au préalable défini</p>
						<p className="detail">
							Réalisation des maquettes et développement en ReactJS d’un backoffice dans l’objectif de faciliter la gestion et l’analyse
							du C.A. journalier, la gestion des produits et des menus.
						</p>
					</div>
					<ProjectFigure src={ASFApp} title="Interface de liaison entre la caisse et la cuisine" />
					<ProjectFigure src={ASFBackoffice} title="Backoffice - Gestion des produits" />
				</section>
				<section className="project-col-2">
					<ProjectFigure src={ASFWebsite} title="Site du restaurant avec le menu" />
					{/* <div className="mission">
						<p className="title">Création et gestion digitale&nbsp;: site web & plateforme d’administration</p>
						<p className="detail">
							Mise en ligne d’un site vitrine afin de faire connaître les spécialités. Conception graphique et développement d’un
							backoffice dans l’objectif de faciliter la gestion et l’analyse du C.A. journalier, la gestion des produits et des menus.
						</p>
					</div> */}
				</section>
			</div>
			<div className="project-missions">
				<section className="project-col-2">
					<div className="mission top left">
						<p className="title">Chargé de l’identité visuelle et de la communication</p>
						<p className="detail">
							Création d’un univers et d’une charte graphique dans le cadre de l’ouverture d’un nouveau restaurant dans le{" "}
							<i className="nospace">Pas-de-Calais</i>
							&nbsp;: logo, enseigne, flyers, menu, carte fidélité,&nbsp;...
						</p>
						<p className="detail">Et donner de la visibilité en ligne via la fiche Google et la page Facebook.</p>
					</div>
					<ProjectFigure src={ASFFlyer} title="Flyer du menu" />
				</section>
				<section className="project-col-2">
					<ProjectFigure src={ASFCarte} title="Carte de fidélité" />
					<div className="mission">
						<p className="title">Service et relations clients & Gestion administratif</p>
						<p className="detail">
							Mise en place des process de prise et de préparation de commandes. Créer de la fidélisation au près de la clientèle.
						</p>
						<p className="detail">
							<span className="star">★★★★★</span>
							4,8/5&nbsp;(98 avis Google)
						</p>
					</div>
				</section>
			</div>
		</Project>
	)
}

export default ProjectASF
