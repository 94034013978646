import "./App.sass"
import Header from "./Components/Header"
import SubNav from "./Components/SubNav"
import ProjectASF from "./Components/Projects/List/ProjectASF.js"
import ProjectBeyable from "./Components/Projects/List/ProjectBeyable.js"
import ProjectPSWD from "./Components/Projects/List/ProjectPSWD.js"
import ProjectDailyUI from "./Components/Projects/List/ProjectDailyUI.js"
import ProjectMainstream from "./Components/Projects/List/ProjectMainstream.js"
import ProjectVie from "./Components/Projects/List/ProjectVie.js"
import Career from "./Components/Career.js"
import { useState, useEffect } from "react"

function App() {
	// const [step, setStep] = useState(1)
	// function isInViewport(el) {
	// 	const rect = el.getBoundingClientRect()
	// 	return (
	// 		rect.top >= 0 &&
	// 		rect.left >= 0 &&
	// 		rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
	// 		rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	// 	)
	// }

	// const handleNav = (action) => {
	// 	const newStep = action === "prev" ? step - 1 : step + 1
	// 	setStep(newStep)
 	// 	const bodyRect = document.body.getBoundingClientRect().top
	// 	const elementRect = document.getElementsByClassName("project")[newStep-1].getBoundingClientRect().top
	// 	const elementPosition = elementRect - bodyRect
	// 	const offsetPosition = elementPosition + 50

	// 	window.scrollTo({
	// 		top: offsetPosition,
	// 		behavior: "smooth",
	// 	})
	// }
	
	return (
		<>
			<div className="guide vertical left"></div>
			<div className="guide vertical right"></div>
			<div className="guide horizontal top"></div>
			<Header />
			<div className="projects-inner">
				{/* <SubNav step={step} second={"hello"} handleClick={(e) => handleNav(e)} /> */}
				<ProjectASF />
				<ProjectBeyable />
				{/* <ProjectPSWD /> */}
				<ProjectDailyUI />
				<ProjectMainstream />
				<ProjectVie />
			</div>
			<Career />
		</>
	)
}

export default App
