import Project from "../Project"
import ProjectFigure from "../ProjectFigure"

import MainstreamAccueil from "../../../Assets/mainstream-accueil.jpg"
import MainstreamCompte from "../../../Assets/mainstream-compte.jpg"
import MainstreamQuiz from "../../../Assets/mainstream-quiz.jpg"
import MainstreamRegles from "../../../Assets/mainstream-regles.jpg"
import MainstreamReponse from "../../../Assets/mainstream-reponse.jpg"
import MainstreamScore from "../../../Assets/mainstream-score.jpg"

const ProjectPSWD = () => {
	return (
		<Project
			type="Projet perso"
			date="2019"
			name="Main Stream théorie"
			challenges={[
				"Proposer une expérience intuitive dans le cadre d’un quiz avec des extraits de films",
				"Développer l'interface du joueur avec les défis de l'univers du jeu",
			]}
			skills={["figma", "xd", "html", "css", "js", "php", "mysql"]}>
			<div className="project-missions mainstream">
				<section>
					<div className="mission top left">
						<p className="title">Création d’une expérience de jeu attrayante</p>
						<p className="detail">Définition d'une roadmap en fonction de l'objectif défini et ainsi pouvoir proposer des fonctionnalités afin de créer de la rétention.</p>
					</div>
				</section>
				<section>
					<ProjectFigure src={MainstreamAccueil} />
				</section>
				<section>
					<ProjectFigure src={MainstreamRegles} />
					<ProjectFigure src={MainstreamQuiz} />
				</section>
				<section>
					<ProjectFigure src={MainstreamCompte} />
					<ProjectFigure src={MainstreamReponse} />
				</section>
				<section>
					<ProjectFigure src={MainstreamScore} />
				</section>
			</div>
		</Project>
	)
}

export default ProjectPSWD
