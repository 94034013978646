import Project from "../Project"
import ProjectFigure from "../ProjectFigure"

import BeyableCampaign1 from "../../../Assets/beyable-campaign-1.jpg"
import BeyableCampaign2 from "../../../Assets/beyable-campaign-2.png"
import BeyableCampaign3 from "../../../Assets/beyable-campaign-3.png"
import BeyableCampaign4 from "../../../Assets/beyable-campaign-4.jpg"
import BeyableCampaign5 from "../../../Assets/beyable-campaign-5.jpg"
import BeyableCampaign6 from "../../../Assets/beyable-campaign-6.jpg"
import BeyableCampaign7 from "../../../Assets/beyable-campaign-7.jpg"
import BeyableCampaign8 from "../../../Assets/beyable-campaign-8.jpg"
import BeyableCampaign9 from "../../../Assets/beyable-campaign-9.jpg"
import BeyableCampaign10 from "../../../Assets/beyable-campaign-10.jpg"
import BeyableCampaign11 from "../../../Assets/beyable-campaign-11.jpg"
import BeyableCampaign12 from "../../../Assets/beyable-campaign-12.png"
import BeyableCampaign13 from "../../../Assets/beyable-campaign-13.jpg"
import BeyableCampaign14 from "../../../Assets/beyable-campaign-14.jpg"
import BeyableCustomization from "../../../Assets/beyable-customization.jpg"
import BeyableDashboard from "../../../Assets/beyable-dashboard.jpg"
import BeyableFlyer from "../../../Assets/beyable-flyer.jpg"
import BeyableLogo from "../../../Assets/beyable-logo.png"
import BeyableWebsite from "../../../Assets/beyable-website.jpg"

const ProjectBeyable = () => {
	return (
		<Project
			type="Web Designer"
			date="2016 ~ 2020"
			name="Beyable"
			challenges={[
				"Proposer des fonctionnalités complexes et les intégrer de manière fluide dans le parcours de l’utilisateur.",
				"S’adapter aux contraintes techniques et aux délais de livraison.",
				"Respecter la charte graphique du client afin de lui proposer des visuelles qui s’adaptent à son univers tout en répondant à la stratégie mise en place par les CSM.",
			]}
			skills={["photoshop", "xd", "html", "css", "js", "angular"]}>
			<div className="project-missions">
				<section className="project-col-2">
					<ProjectFigure src={BeyableDashboard} title="Tableau de bord du backoffice" />
				</section>
				<section className="project-col-2">
					<div className="mission top">
						<p className="title">Évolution du produit SaaS</p>
						<p className="detail">
							En coordination avec la Product Owner, l’équipe CSM et IT, j'ai participé à la prise de besoin et aux propositions de nouvelles fonctionnalités afin d’offrir un
							outil SaaS complet et évolutif.
						</p>
						<p className="detail">Assister l'équipe technique en intégrant la partie front selon le cahier des charges et les contraintes techniques.</p>
					</div>
					<ProjectFigure src={BeyableCustomization} title="Outil de création de campagne de remarketing" />
				</section>
			</div>
			<div className="project-missions beyable-scenario">
				<section className="cell-1">
					<div className="mission top left">
						<p className="title">
							Création de scénarios marketing <i className="nospace">on-site</i> et <i className="nospace">off-site</i>
						</p>
						<p className="detail">
							Proposition visuelles, intégration et mise en ligne des scénarios marketing chez les clients, en respectant l’objectif
							définit par l’équipe de CSM et les contraintes techniques
						</p>
					</div>
				</section>
				<section className="project-stats cell-2">
					<div>
						<p>1200+</p>
						<span>Créas</span>
					</div>
					<div>
						<p>158+</p>
						<span>Clients</span>
					</div>
				</section>
				<section className="cell-3">
					<ProjectFigure src={BeyableCampaign7} />
					<ProjectFigure src={BeyableCampaign8} />
				</section>
				<section className="cell-4">
					<ProjectFigure src={BeyableCampaign1} />
					<ProjectFigure src={BeyableCampaign2} />
				</section>
				<section className="cell-5">
					<ProjectFigure src={BeyableCampaign3} />
					<ProjectFigure src={BeyableCampaign4} />
					<ProjectFigure src={BeyableCampaign5} />
				</section>
				<section className="cell-6">
					<ProjectFigure src={BeyableCampaign9} />
					<ProjectFigure src={BeyableCampaign10} />
				</section>
				<section className="cell-7">
					<ProjectFigure src={BeyableCampaign6} />
				</section>
				<section className="cell-8">
					<ProjectFigure src={BeyableCampaign11} />
					<ProjectFigure src={BeyableCampaign13} />
				</section>
				<section className="cell-9">
					<ProjectFigure src={BeyableCampaign12} />
					<ProjectFigure src={BeyableCampaign14} />
				</section>
			</div>
			<div className="project-missions beyable-identity">
				<section>
					<div className="mission top left">
						<p className="title">Chargé de l’identité visuelle</p>
						<p className="detail">
							Faire évoluer la charte graphique déjà existante en fonction des valeurs à transmettre. Et réalisation d’éléments
							visuelles&nbsp;: logo, site web, flyer, support de présentation,&nbsp;...
						</p>
					</div>
					<ProjectFigure src={BeyableFlyer} title="Flyer de présentation" />
				</section>
				<section>
					<ProjectFigure src={BeyableLogo} title="Évolution du logo" />
				</section>
				<section>
					<ProjectFigure src={BeyableWebsite} title="Site web de présentation de la solution" />
				</section>
			</div>
		</Project>
	)
}

export default ProjectBeyable
