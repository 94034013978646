import { useMemo } from "react"
import IconAngular from "../../Assets/skill-angular.png"
import IconCss from "../../Assets/skill-css.png"
import IconFigma from "../../Assets/skill-figma.png"
import IconHtml from "../../Assets/skill-html.png"
import IconJavascript from "../../Assets/skill-javascript.png"
import IconMysql from "../../Assets/skill-mysql.png"
import IconPhotoshop from "../../Assets/skill-photoshop.png"
import IconPhp from "../../Assets/skill-php.png"
import IconReact from "../../Assets/skill-react.png"
import IconXd from "../../Assets/skill-xd.png"

const Skills = (props) => {
    const SkillIcons = useMemo(
		() => [
			{ label: "figma", name: "Figma", img: IconFigma },
			{ label: "photoshop", name: "Photoshop", img: IconPhotoshop },
			{ label: "xd", name: "Adobe XD", img: IconXd },
			{ label: "html", name: "HTML", img: IconHtml },
			{ label: "css", name: "CSS", img: IconCss },
			{ label: "js", name: "Javascript", img: IconJavascript },
			{ label: "react", name: "React", img: IconReact },
			{ label: "angular", name: "Angular", img: IconAngular },
			{ label: "php", name: "PHP", img: IconPhp },
			{ label: "mysql", name: "MySql", img: IconMysql },
		],
		[]
	)

	return (
		<ul className="project-skills">
			{SkillIcons.filter((skill) => props.active.indexOf(skill.label) !== -1).map((skill, index) => (
				<li key={index}>
					<img src={skill.img} alt="" />
					<p>{skill.name}</p>
				</li>
			))}
		</ul>
	)
}

export default Skills
