export const Header = () => {

    // Dots bloc --------------------------------
	const dotsNumber = 900
	const dots = []

    // Create dots
	for (var i = 0; i < dotsNumber; i++) {
		dots.push(<span key={i} id={`dots-${i}`}></span>)
	}
    // Dots animation
    setInterval(() => {
        const rand = parseInt(Math.random() * dotsNumber)
            document.getElementById("dots-" + rand).classList.add("glow")
            setTimeout(() => {
                document.getElementById("dots-" + rand).classList.remove("glow")
            }, 2000)
    }, 100)

	const exp = new Date().getFullYear() - 2016

	return (
		<header>
			<section className="dots-grid">{dots}</section>
			<main>
				<h1>Régie huang</h1>
				<h2>
					Web designer & <i className="nospace">dev front</i>
				</h2>
				<div className="text">
					<p>Web designer et développeur front depuis maintenant {exp} ans, à la recherche de challenges dans les applications SaaS.</p>
					<p>
						Je souhaite constamment tester les limites de mes connaissances graphiques et techniques afin d'élargir mes champs
						de compétences.
					</p>
					<p>
						Mon objectif : Comprendre et définir un besoin afin de designer et développer des interfaces graphiques fonctionnelles et
						intuitives. Et proposer aux utilisateurs une expérience fluide.
					</p>
				</div>
			</main>
			<nav>
				{/* <ul>
					<li>Projets</li>
					<li>Parcours</li>
					<li>Contact</li>
				</ul> */}
				{/* <a href="" className="phone-number">
					<span>(+33)</span> <i>06 58 81 53 65</i>
				</a> */}
			</nav>
		</header>
	)
}
export default Header
