const Career = () => {
	return (
		<section className="career-wrapper">
			<aside className="career">
				<h3>Mon Parcours</h3>
				<p className="chapeau">
					Diplômé en Manager de projets web en 2016, actuellement freelance et disponible pour des missions de web design et de
					développement <i className="nospace">front-end</i>.
				</p>
				<p>
					En recherche constante de nouveaux challenges graphiques et techniques afin de monter en compétence et de les mettre en pratique ainsi qu'en acquérir de nouvelles.
				</p>
				<p>
					Après une année de licence en mathématiques et informatique, je me suis finalement tourné vers le monde du digital, me découvrant
					un intérêt pour les applications web et une envie de faciliter le quotidien des utilisateurs à travers des interfaces claires et simple
					d’utilisation.
				</p>
			</aside>
			<section className="career-timeline">
				<div className="career-content">
					<div className="time-col">
						<p>Aujourd'hui</p>
					</div>
					<div className="time-col">
						<p>2021</p>
					</div>
					<div className="time-col out">
						<p>2020</p>
					</div>
					<div className="time-col out">
						<p>2019</p>
					</div>
					<div className="time-col out">
						<p>2018</p>
					</div>
					<div className="time-col out">
						<p>2017</p>
					</div>
					<div className="time-col">
						<p>2016</p>
					</div>
					<div className="time-col"></div>

					<div className="career-exp asf">
						<p>
							<span>Asian Street Food</span>
						</p>
					</div>
					<div className="career-exp beyable">
						<p>
							<span>BEYABLE</span>
						</p>
					</div>
					<div className="career-exp freelance">
						<p>
							<span>Freelance</span>
						</p>
					</div>
					<div className="career-study">
						<p>
							Diplômé en Web design et manager de projet web <i>-&nbsp;SupInternet&nbsp;-</i>
						</p>
					</div>
					<span className="timeline-v"></span>
					<span className="timeline-v half"></span>
				</div>
			</section>
		</section>
	)
}

export default Career
