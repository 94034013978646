import Project from "../Project"
import ProjectFigure from "../ProjectFigure"

import VieBoard from "../../../Assets/vie-board.jpg"
import VieRegles from "../../../Assets/vie-regles.jpg"

const ProjectPSWD = () => {
	return (
		<Project
			type="Projet perso"
			date="2018"
			name="Jeu de la vie"
			challenges={[
				"Reproduire techniquement un concept mathématiques qui répond à une logique simple",
				"Découverte de p5.js, une librairie Javascript qui permet de créer des formes et de les animer.",
			]}
			skills={["xd", "html", "css", "js"]}>
			<div className="project-missions">
				<section className="project-col-2">
					<div className="mission top left">
						<p className="title">Simulation du comportement de «&nbsp;cellules&nbsp;» en développement</p>
						<p className="detail">
							Un jeu qui illustrent le développement complexe de la «&nbsp;vie&nbsp;» selon 3 règles simples&nbsp;:
						</p>
						<ul className="detail">
							<li>La survie d’une cellule bien entourée</li>
							<li>La mort par isolement ou sur-population</li>
							<li>La création d’une cellule</li>
						</ul>
					</div>
					<ProjectFigure src={VieBoard} title="Interface de simulation" />
				</section>
				<section className="project-col-2">
					<ProjectFigure src={VieRegles} title="Règles d’évolution" />
				</section>
			</div>
		</Project>
	)
}

export default ProjectPSWD
