import Project from "../Project"
import ProjectFigure from "../ProjectFigure"

import UIBlog from "../../../Assets/ui-challenge-blog.jpg"
import UICalculatrice from "../../../Assets/ui-challenge-calculatrice.jpg"
import UIEmail from "../../../Assets/ui-challenge-email.jpg"
import UIProduct from "../../../Assets/ui-challenge-product.jpg"
import UIProfil from "../../../Assets/ui-challenge-profil.jpg"

const ProjectDailyUI = () => {
	return (
		<Project
			type="Projet perso"
			date="2020"
			name="Daily UI"
			challenges={[
				"Être force de proposition et se réinventer à chaque challenge. C’était aussi l’occasion de tester différents univers graphiques et faire face à des problématiques d’UI et d’UX variées",
			]}
			skills={["xd"]}>
			<div className="project-missions daily-ui">
				<section className="project-col-2">
					<ProjectFigure src={UIEmail} title="Email de confirmaiton de commande" />
					<ProjectFigure src={UIProduct} title="Fiche de présentation d’un produit" />
				</section>
				<section className="project-col-2">
					<ProjectFigure src={UIProfil} title="Profil d’une photographe" />
					<ProjectFigure src={UIBlog} title="Article de blog" />
					<ProjectFigure src={UICalculatrice} title="Calculatrice" />
				</section>
			</div>
		</Project>
	)
}

export default ProjectDailyUI
