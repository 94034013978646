import Skills from "./Skills"

const Project = (props) => {
	const Grid = () => {
		return (
			<div className="project-grid">
				<span/><span/><span/><span/><span/><span/>
			</div>
		)
	}
	return (
		<section className="project">
			<aside className="project-infos">
				<div className="project-infos-inner">
					<p className="project-type">
						{props.type}
						<span>{props.date && props.date}</span>
					</p>
					<h3 className="project-name">{props.name}</h3>
					<p className="project-title">{props.nextChallenge ? "prochains Challenges" : "Challenges"}</p>
					<div className="project-challenges">
						{props.challenges.map((challenge, index) => (
							<p key={index}>{challenge}</p>
						))}
					</div>
					<p className="project-title">Compétences</p>
					<Skills active={props.skills} />
				</div>
			</aside>
			<section className="project-wrapper">
				<Grid />
				{props.children}
			</section>
		</section>
	)
}

export default Project
